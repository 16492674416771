<template>
  <LoginContainer>
    <el-form
        @submit.native.prevent="submitForm"
        class="login-form"
    >
      <div class="login-container--header">
        {{ $t('system.reset_password') }}
        <lang-select
            @changeLanguage="changeLanguage"
            class="login-lang-select"
        ></lang-select>
      </div>
      <div class="title-container">
        <img
            :src="logoImage"
            class="center-block logo"
            v-if="logoImage"
        >
        <h3
            class="title"
            v-else
        >{{ $t('system.reset_password') }}</h3>
      </div>
      <el-form-item required>
        <el-input
            :class="checkinputRequired('email') ? 'validate-error' : ''"
            name="email"
            placeholder="email@example.com"
            v-model="email"
        >
          <i slot="prefix">
            <font-awesome-icon icon="user" />
          </i>
        </el-input>
        <small
            class="help-block"
            v-if="backend_errors.email && !checkinputRequired('email')"
        >
          <span v-html="$t(backend_errors.email[0])"></span>
        </small>
      </el-form-item>
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="login">{{ $t('system.back_to_login') }}</router-link>
        <el-button
            :disabled="loading"
            :loading="loading"
            class="auth--buttonPrimary"
            native-type="submit"
            type="primary"
        >{{ $t('system.reset_password') }}
        </el-button>
      </div>
    </el-form>
  </LoginContainer>
</template>
<script>
import axios from 'axios';
import LangSelect from '../../components/LangSelect/index.vue';
import notification from '../../notification/notify';
import LoginContainer from './LoginContainer.vue';

export default {
  name: 'forgot-password',
  components: {LoginContainer, LangSelect},
  data() {
    return {
      email: '',
      backend_errors: {},
      inputRequiredEmpty: [],
      loading: false,
    };
  },
  computed: {
    logoImage() {
      return this.$store.getters.logo;
    },
  },
  methods: {
    submitForm() {
      this.checkValidation().then(() => {
        this.loading = true;
        axios.post('password/forget', {email: this.email})
            .then(() => {
              notification.notify(
                  this.$t('notify.success'),
                  this.$t('message.message_sent'),
                  'success');
              this.$router.push('/login');
            })
            .catch((error) => {
              this.backend_errors = error.response.data.errors ? error.response.data.errors : {email: ['system.no_user']};
            })
            .finally(() => {
              this.loading = false;
            });
      });
    },
    changeLanguage(lang) {
      window.localStorage.setItem('langGlobal', lang);
      this.changeLanguageMixin(lang).then(() => {
        document.title = this.$t('system.reset_password');
      });
    },
  },

};
</script>
